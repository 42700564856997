import { type RefObject, useEffect, useState } from "react";

export function useActiveIndex(
	ref: RefObject<HTMLDivElement>,
	// additional delta to containerWidth (for example gap between slides).
	delta = 0,
) {
	const [activeIndex, setActiveIndex] = useState(0);

	useEffect(() => {
		const containerElement = ref?.current as HTMLDivElement;
		const firstElement = containerElement.firstElementChild;

		const update = () => {
			const infoContainerElement = containerElement.getBoundingClientRect();
			const infoFirstElement = firstElement.getBoundingClientRect();
			const shiftFirstElement = infoFirstElement.left;
			if (!shiftFirstElement) {
				setActiveIndex(0);

				return;
			}

			const containerWidth = infoContainerElement.width;
			const offset = containerWidth + delta;
			if (!offset) {
				setActiveIndex(0);

				return;
			}

			const newActiveIndex = Math.round(Math.abs(shiftFirstElement / offset));
			if (Number.isNaN(newActiveIndex)) {
				setActiveIndex(0);

				return;
			}

			setActiveIndex(newActiveIndex);
		};

		update();

		containerElement?.addEventListener("scroll", update, { passive: true });

		return () => {
			containerElement?.removeEventListener("scroll", update, {
				// Typescript does not accept passive as an option currently
				// @ts-ignore
				passive: true,
			});
		};
	}, []);

	return activeIndex;
}
