import { type RefObject, useEffect, useState } from "react";

export function useOnceScrolled(ref: RefObject<HTMLDivElement>) {
	const [wasScrolled, setWasScrolled] = useState(false);

	useEffect(() => {
		const containerElement = ref?.current as HTMLDivElement;

		// There is more proper event scrollend, but it doesn't work at Safari.
		// Because of that using this hack.
		const update = () => {
			setWasScrolled(true);
		};

		containerElement?.addEventListener("scroll", update, {
			once: true,
			passive: true,
		});

		return () => {
			containerElement?.removeEventListener("scroll", update, {
				// Typescript does not accept passive as an option currently
				// @ts-ignore
				once: true,
				passive: true,
			});
		};
	}, []);

	return wasScrolled;
}
