import type { SxStyleProp } from "theme-ui";

/*
  We maximum have 5 images for grid. Before it was tried grid decision.
  But grid's doesn't work with images more than five.
  Because of that I decided to use position absolute for moving grid inside
  of the container.
  &:nth-of-type property help to choose child by index.
*/
export const galleryGridStylesMapping: Record<number, SxStyleProp> = {
	1: {
		gridTemplateAreas: [
			null,
			`
      "image1 image1 image1"
      "image1 image1 image1"
    `,
		],
	},
	2: {
		gridTemplateAreas: [
			null,
			`
      "image1 image2 image2"
      "image1 image2 image2"
    `,
		],
	},
	3: {
		gridTemplateAreas: [
			null,
			`
      "image1 image2 image2"
      "image1 image3 image3"
    `,
		],
	},
	4: {
		gridTemplateAreas: [
			null,
			`
      "image1 image2 image3"
      "image1 image4 image4"
    `,
		],
	},
	5: {
		gridTemplateAreas: [
			null,
			`
      "image1 image2 image3"
      "image1 image4 image5"
    `,
		],
	},
};

export const gallerySizeContainer: SxStyleProp = {
	minHeight: ["238px", "300px"],
	maxHeight: "60vh",
	maxWidth: "100vw",
};

export const commonGalleryButtonMobileStyles: SxStyleProp = {
	display: ["block", "none"],
	position: "absolute",
	top: "50%",
	transform: "translateY(-50%)",
	zIndex: 3,
	userSelect: "none",
	width: "38px",
	height: "38px",
	touchAction: "manipulation",
};

export const increaseIndex = (currentIndex: number, size: number): number =>
	(currentIndex + 1) % size;

export const decreaseIndex = (currentIndex: number, size: number): number =>
	(currentIndex - 1 + size) % size;

interface UpdateScrollImage {
	containerRef: React.MutableRefObject<HTMLDivElement>;
	newActiveIndex: number;
	imageGap?: number;
}

export const updateScrollImage = ({
	containerRef,
	newActiveIndex,
	imageGap = 0,
}: UpdateScrollImage) => {
	const infoContainerElement = containerRef.current.getBoundingClientRect();
	const containerWidth = infoContainerElement.width;
	containerRef.current.scrollTo({
		left: (containerWidth + imageGap) * newActiveIndex,
		behavior: "smooth",
	});
};
